
import Vue from "@/interfaces/vue";
import { Component, Watch } from "vue-property-decorator";
import Newsletter from "@/components/common/Newsletter.vue";
import Content from "@/components/common/Content.vue";
import { DataDownloader, get } from "@/api";
import { VueperSlides, VueperSlide } from "vueperslides";
import { Fragment } from "vue-fragment";
import { Therapist } from "@/interfaces/therapist";
import { ChallengeShortInfo } from "@/interfaces/challenge";
import i18n from "@/i18n";
import { ChallengePaymentInfo } from "@/interfaces/payment";
const pageKey = "home";

@Component({
  components: {
    Content,
    Newsletter,
    VueperSlide,
    VueperSlides,
    Fragment,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class Home extends Vue {
  therapists = new DataDownloader<Therapist[]>(get.therapists);

  challenges = new DataDownloader<ChallengeShortInfo[]>(() => {
    if (this.$store.getters.isLoggedIn) return get.challengeShortInfo();
    return get.challengePreview();
  });

  baseUrl = process.env.VUE_APP_TARGET;

  therapistHeight = 0;
  InfoCardHeight = 0;

  mounted() {
    this.$nextTick(function() {
      this.setInfoCardHeight();
    });
  }

  get mainChallenge() {
    return this.challenges.data?.find((challenge) => challenge.id === 7);
  }

  @Watch("therapists.loaded")
  onTherapistsDownload() {
    if (this.therapists.loaded)
      this.$nextTick(() => this.setTherapistCardHeight());
  }

  setInfoCardHeight() {
    const elem = document.getElementById("biggestInfoCard");
    if (elem) {
      this.InfoCardHeight = elem.offsetHeight + 80;
      const parent = elem.parentElement?.parentElement;
      parent?.childNodes.forEach((node) => {
        if (node.childNodes.length)
          (node.childNodes[1] as HTMLElement).style.bottom = "1rem";
      });
    }
  }

  setTherapistCardHeight() {
    this.therapistHeight =
      Math.max(
        ...(this.$refs.therapistCards as Vue[]).map(
          (ref) => (ref.$el.children[0] as HTMLElement).offsetHeight
        )
      ) + 36;
    (this.$refs.therapistCards as Vue[]).forEach(
      (ref) => ((ref.$el.children[0] as HTMLElement).style.bottom = "1rem")
    );
  }

  trimDescription(desc: string, length: number) {
    if (desc.length > length)
      for (let i = length + 1; i < desc.length; i++) {
        if (desc[i] === " ") return desc.slice(0, i) + "...";
      }
    else return desc;
  }

  proceedToPayment() {
    this.$store.commit("setPayment", this.mainChallenge as ChallengeShortInfo);
    this.$router.push("/payment-challenge");
  }
}
